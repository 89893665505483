<template>
  <div>正在进入系统访问入口...</div>
</template>
<script>
import Config from "@/api/service";

export default {
  name: "Access",
  components: {
  },
  data() {
    return {};
  },
  mounted() {
    try {
      var proxy = this.$route.query.proxyCode;
      var phone = this.$route.query.phoneCode;
      var sign = this.$route.query.sign;
      var md5 = this.$md5(`proxyCode=${proxy}&phoneCode=${phone}`).toUpperCase();
      if (sign == md5) {
        var model = {
          UserPhone: phone,
          ProxyCode: proxy
        };
        this.$axios
          .post(Config.AddUserInfo, model)
          .then((res) => {
            this.$store.commit("setToken", {
              Token: res.data.Data.Token
            });
            this.$router.push({ name: "Home", query: { proxyCode: proxy } });
          })
          .catch((error) => {
            this.$dialog({
              title: "错误",
              message: error.message,
            });
          });
      } else {
        this.$dialog.alert({ title: "错误", message: "签名错误。" });
      }
    } catch (error) {
      this.$dialog.alert({ title: "错误", message: error.message });
    }
  },
};
</script>